// @author:Yohannes Assebe
/*eslint-disable*/
import api from "../adapter/base";
import { useQuery, useMutation } from "react-query";

// fetch orders
const getOrders = async () => {
  const res = await api.get("orders?user");
  const dt = res.data.data;
  return dt;
};

// fetch accepted orders
const getAcceptedOrders = async () => {
  const res = await api.get("/accepted-orders");
  const response = res?.data?.data;
  return response;
};

// fetch orders with params
const getOrderWithParameters = async (params) => {
  const parseparam = (params) => {
    var param = [];
    Object.keys(params).map((key) => {
      if (params[key] === "") return param.push(`?${key}`);
      param.push(`?${key}=${params[key]}`);
    });
    return param.join("");
  };
  const reqparams = parseparam(params.queryKey[1]);
  const response = await api.get(`orders${reqparams}`);
  return response?.data?.data;
};
// used to Download Excel file of filtered order
const DownloadFilteredOrders = (status = null, date = null) => {
  return api.get(
    `orders/export?${status ? `status=${status}` : ``}${
      date
        ? `&date[from]=${date[0].format().split("T")[0]}&date[until]=${
            date[1].format().split("T")[0]
          }`
        : ``
    }`,
    { responseType: "blob" }
  );
};

// fetch order for report management
const fetchFilteredOrders = async (status, date, page) => {
  if (status === "all") {
    status = null;
  }
  const data = await api.get(
    `orders?user${status ? `&status=${status}` : ``}${
      page ? `&page=${page}` : ``
    }${
      date
        ? `
  &date[from]=${date[0].format().split("T")[0]}&date[until]=${
            date[1].format().split("T")[0]
          }
  `
        : ``
    }`
  );
  return data?.data;
};

export const fetchSearchedOrders = async (filters = null, page = 1) => {
  const response = await api.get(
    `orders?user${filters?.order_id ? `&id=${filters?.order_id}` : ``}${
      filters?.customer_name ? `&customer_name=${filters?.customer_name}` : ``
    }${
      filters?.customer_phone_number
        ? `&customer_phone_number=${filters?.customer_phone_number}`
        : ``
    }${filters?.status ? `&status=${filters?.status[0]}` : ``}${
      filters?.dateRange
        ? `&date%5Bfrom%5D=${
            filters?.dateRange[0].format().split("T")[0]
          }&date%5Buntil%5D=${filters?.dateRange[1].format().split("T")[0]}`
        : ``
    }&page=${page}`
  );
  const data = await response.data;
  return data;
};

const acceptPendingPickup = (orderId) => {
  return api.post(`orders/${orderId}/accept-pickup`);
};

const approvePickup = (order) => {
  return api.post(`orders/${order.orderId}/approve-pickup`, order.data);
};

const downloadExcelFile = () => {
  return api.get(`users/download-excel-template`, { responseType: "blob" });
};

const getOrderStat = async () => {
  const data = await api.get("stats/orders/daily");
  const res = data?.data?.data;
  return res;
};

const checkDeliveryStatus = async (order_id) => {
  const data = await api.get(`orders/${order_id}/delivery-status`);
  return data;
};

const retryPickUpDelivery = (order_id) => {
  return api.post(`orders/${order_id}/retry-pickup-delivery`);
};

const viewTripStatus = async (order_id) => {
  const tripStatus = await api.get(`orders/${order_id}/view-trip-status`);
  return tripStatus;
};


// react-query
export const useAllOrders = () => {
  return useQuery("allOrders", getOrders);
};

export const useOrderWithParam = (param) => {
  return useQuery(["order", param], getOrderWithParameters);
};

export const useAcceptedOrder = () => {
  return useQuery("accepted-orders", getAcceptedOrders);
};

export const useDownloadExcel = () => {
  return useQuery("exceldownloader", downloadExcelFile);
};

export const useAcceptOrder = () => {
  return useMutation(acceptPendingPickup);
};

export const useApproveOrder = () => {
  return useMutation(approvePickup);
};

export const useDownloadFilteredOrderInExcel = (status, date) => {
  return useMutation(() => DownloadFilteredOrders(status, date));
};

export const useReportOrder = (status, date, page) => {
  return useQuery(["reportOrder", status, date, page], () =>
    fetchFilteredOrders(status, date, page)
  );
};

export const useStatOrder = () => {
  return useQuery("statData", getOrderStat);
};


export const useDeliveryStatus = () => {
  return useMutation(checkDeliveryStatus);
};

export const useRetryPickUpDelivery = () => {
  return useMutation(retryPickUpDelivery);
};

export const useViewTripStatus = () => {
  return useMutation(viewTripStatus);
};
