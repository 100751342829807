import api from "../adapter/base";
import { useQuery } from "react-query";
// fetch rating
const ratings = async () => {
  const res = await api.get("ratings");
  return res.data;
};

export const useRatings = () => {
  return useQuery("rating", ratings);
};
