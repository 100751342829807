// @author : Yohannes Assebe
import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
function Nav(props) {
  return (
    <>
      <div>
        <Menu
          mode="horizontal"
          defaultSelectedKeys={props.nav_link}
          overflowedIndicator={<MenuOutlined />}
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
          className="w-[60%] ml-[10%]"
        >
          <Menu.Item key="main_page">
            <Link
              to="dashboard"
              className="text-lg text-center font-bold items-center "
            >
              ዋና ገጽ
            </Link>
          </Menu.Item>
          <Menu.Item key="products">
            <Link to="products"> Products </Link>
          </Menu.Item>

          <Menu.Item key="fullfillment">
            <Link to="fullfillment">Fullfillment</Link>
          </Menu.Item>

          <Menu.Item key="reports">
            <Link to="report">Reports</Link>
          </Menu.Item>

          <Menu.Item key="reviews">
            <Link to="reviews">Customer Insights</Link>
          </Menu.Item>
        </Menu>
      </div>
    </>
  );
}

export default Nav;
