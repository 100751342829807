import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Upload, Input, Divider, message } from "antd";
import { useEffect, useState } from "react";
import { useEditCategory } from "../hooks/useCategories";
import { useQueryClient } from "react-query";
const formlayout = {
  labelCol: {
    span: 20,
  },
  wrapperCol: {
    span: 24,
  },
};
const uploadButton = (
  <div>
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
      }}
    >
      Upload
    </div>
  </div>
);
const EditCategory = ({ categoryForOperations, closeEditCategoryDrawer }) => {
  const [form] = Form.useForm();
  const [initialData, setInitialData] = useState();

  //variable to update category
  const { mutate: updateCategory, isLoading: load } = useEditCategory();
  const client = useQueryClient();

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const onFinish = (values) => {
    const fd = new FormData();
    if (values["upload"]?.length) {
      fd.append(
        "image",
        values["upload"][0].originFileObj,
        values["upload"][0].originFileObj.name
      );
      fd.append("remove_image", 1);
      delete values["upload"];
    } else {
      delete values["upload"];
    }
    Object.keys(values).map((key) => {
      return fd.append(key, values[key]);
    });
    fd.append("_method", "put");
    updateCategory(
      { id: categoryForOperations?.id, category: fd },
      {
        onSuccess: (data) => {
          message.success(data?.data?.data?.name + " Updated");
          client.invalidateQueries("allCategories");
          form.resetFields();
          closeEditCategoryDrawer();
        },
        onError: (error) => {
          message.warning(error?.response?.data?.message);
        },
      }
    );
  };

  useEffect(() => {
    setInitialData(categoryForOperations);
    form.setFieldsValue(initialData);
  }, [initialData, form, categoryForOperations]);

  return (
    <div className="items-center text-center -mt-4">
      <Divider>
        <h4>update Category</h4>
      </Divider>
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
        {...formlayout}
        style={{
          maxWidth: 300,
        }}
      >
        <Form.Item
          label="Category Name"
          name="name"
          rules={[
            {
              required: true,
              message: "category name is required",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="ስም በአማርኛ"
          name="name_am"
          rules={[
            {
              required: true,
              message: "category name in amharic is required",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="text-center"
          name="upload"
          label="የምድቡ ምስል"
          valuePropName="fileList"
          accept=".jpg, .jpeg, .png, .bmp, .gif, .svg,.webp"
          getValueFromEvent={normFile}
          multiple={false}
        >
          <Upload
            name="upload"
            listType="picture-card"
            multiple={false}
            maxCount={1}
            beforeUpload={(file) => {
              return false;
            }}
          >
            {uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            span: 15,
            offset: 2,
          }}
        >
          <Button htmlType="submit" loading={load}>
            Update
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default EditCategory;
