// @author : Yohannes Assebe
/*eslint-disable*/
import { useState } from "react";
import { Tabs } from "antd";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  SearchOutlined,
  ShoppingOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import SearchBar from "../components/searchBars";
import SearchedOrders from "../components/searchedOrders";
// import AcceptPendingPickup from "../components/pendingPickup";
import AcceptedOrders from "../components/acceptedOrders";
import PendingDelivery from "../components/pendingDelivery";
import SuccessfullyDelivered from "../components/successfullyDelivered";
const Fullfillment = () => {
  const [filters, setFilters] = useState();
  const [page] = useState(1);
  const tabItems = [
    {
      key: "searched-orders",
      label: (
        <span>
          <SearchOutlined />
          Searched Results
        </span>
      ),
      children: <SearchedOrders filters={filters} pageToDefault={page} />,
    },
    // {
    //   key: "accept-pending-pickup",
    //   label: (
    //     <span>
    //       <ShoppingOutlined />
    //       Accept Pending Pickup
    //     </span>
    //   ),
    //   children: <AcceptPendingPickup />,
    // },
    {
      key: "accept-order",
      label: (
        <span>
          <CheckCircleOutlined />
          Accept Order
        </span>
      ),
      children: <AcceptedOrders />,
    },
    {
      key: "pending-delivery",
      label: (
        <span>
          <ClockCircleOutlined />
          Pending Delivery
        </span>
      ),
      children: <PendingDelivery />,
    },
    {
      key: "successfully-delivered",
      label: (
        <span>
          <SmileOutlined />
          Successfully Delivered
        </span>
      ),
      children: <SuccessfullyDelivered />,
    },
  ];
  const handleFiltersChange = (newFilter) => {
    setFilters(newFilter);
  };
  return (
    <>
      <SearchBar onFiltersChange={handleFiltersChange} />
      <div className="flex justify-center">
        <Tabs items={tabItems} />
      </div>
    </>
  );
};

export default Fullfillment;
