import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Upload, Input, Divider, message } from "antd";
import { useAddCategory } from "../hooks/useCategories";
import { useQueryClient } from "react-query";
const formlayout = {
  labelCol: {
    span: 20,
  },
  wrapperCol: {
    span: 24,
  },
};
const uploadButton = (
  <div>
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
      }}
    >
      Upload
    </div>
  </div>
);
const AddCategory = ({ closeAddCategoryDrawer }) => {
  const [form] = Form.useForm();
  //query mutation to register Category
  const { mutate: addCategory, isLoading: load } = useAddCategory();
  const client = useQueryClient();

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const onFinish = (values) => {
    const fd = new FormData();
    if (values["upload"]?.length) {
      fd.append(
        "image",
        values["upload"][0].originFileObj,
        values["upload"][0].originFileObj.name
      );
      delete values["upload"];
    } else {
      delete values["upload"];
    }
    Object.keys(values).map((key) => {
      return fd.append(key, values[key]);
    });

    addCategory(fd, {
      onSuccess: (data) => {
        message.success(data?.data?.data?.name + " Registered");
        client.invalidateQueries("allCategories");
        form.resetFields();
        closeAddCategoryDrawer();
      },
      onError: (error) => {
        message.warning(error?.response?.data?.message);
      },
    });
  };

  return (
    <div className="items-center text-center -mt-4">
      <Divider>
        <h4>Regsiter New Category</h4>
      </Divider>
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
        {...formlayout}
        style={{
          maxWidth: 300,
        }}
      >
        <Form.Item
          label="Category Name"
          name="name"
          rules={[
            {
              required: true,
              message: "category name is required",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="ስም በአማርኛ"
          name="name_am"
          rules={[
            {
              required: true,
              message: "category name in amharic is required",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="text-center"
          name="upload"
          label="የምድቡ ምስል"
          valuePropName="fileList"
          accept=".jpg, .jpeg, .png, .bmp, .gif, .svg,.webp"
          getValueFromEvent={normFile}
          multiple={false}
        >
          <Upload
            name="upload"
            listType="picture-card"
            multiple={false}
            maxCount={1}
            beforeUpload={(file) => {
              return false;
            }}
          >
            {uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            span: 15,
            offset: 2,
          }}
        >
          <Button htmlType="submit" loading={load}>
            Register
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default AddCategory;
