// @author : Yohannes Assebe
import "antd/dist/reset.css";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClientProvider, QueryClient } from "react-query";
import Login from "./common/login";
import Home from "./common/layout";
import DashBoard from "./views/dashboard";
import FullFillment from "./views/fullfillment";
import Products from "./views/products";
import Report from "./views/reports";
import NotFoundPage from "./components/notFound404";
import LandingPage from "./components/landingPage";
import Review from "./views/reviews";
const client = new QueryClient();
function App() {
  return (
    <>
      <QueryClientProvider client={client}>
        <Router>
          <Routes>
            {["/", "/login"].map((path, index) => (
              <Route path={path} element={<Login />} key={index} />
            ))}
            <Route path="/restaurant" element={<Home />}>
              <Route index element={<LandingPage />} />
              <Route path="dashboard" element={<DashBoard />} />
              <Route path="fullfillment" element={<FullFillment />} />
              <Route path="products" element={<Products />} />
              <Route path="report" element={<Report />} />
              <Route path="reviews" element={<Review/>}/>
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
      </QueryClientProvider>
    </>
  );
}

export default App;
