/*eslint-disable*/
// @author : Yohannes Assebe
import { useState } from "react";
import {
  Button,
  Spin,
  Divider,
  Card,
  Image,
  Carousel,
  Drawer,
  Popconfirm,
  message,
  Pagination,
  Popover,
  Input,
} from "antd";
import { useDebounce } from "../hooks/useDebounce";
import { IoFastFoodOutline } from "react-icons/io5";
import { BiCategoryAlt } from "react-icons/bi";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useAllCategories, useDeleteCategory } from "../hooks/useCategories";
import {
  IoIosAddCircleOutline,
  IoIosRemoveCircleOutline,
} from "react-icons/io";
import { useDeleteProduct } from "../hooks/useProducts";
import api from "../adapter/base";
import { useQuery, useQueryClient } from "react-query";
import NoImage from "../images/No-Image.png";
import AddProduct from "../drawer/addProduct";
import AddCategory from "../drawer/addCategory";
import EditProduct from "../drawer/editProduct";
import EditCategory from "../drawer/editCategory";
const { Meta } = Card;

const fetchProducts = async (page = 1, category_id, searchTerm) => {
  const response = await api.get(
    `products?page=${page}${category_id ? `&category_id=${category_id}` : ``}${
      searchTerm ? `&name=${searchTerm}` : ``
    }&paginate=12`
  );
  const data = await response.data;
  return data;
};

const Products = () => {
  //list of categories
  const [allCategories, setAllCategories] = useState();
  // product and category for operations like edit and delete
  const [productForOperation, setProductForOperation] = useState();
  const [categoryForOperation, setCategoryForOperation] = useState();

  //variables used to manage pagination and category change
  const [page, setPage] = useState(1);
  const [categoryChange, setCategoryChange] = useState(null);

  //variables for drawer controllers
  const [addProductDrawer, setAddProductDrawer] = useState(false);
  const [addCategoryDrawer, setAddCategoryDrawer] = useState(false);
  const [editProductDrawer, setEditProductDrawer] = useState(false);
  const [editCategoryDrawer, setEditCategoryDrawer] = useState(false);

  //variable used to search for products
  // handle product search
  const [searchText, setSearchText] = useState("");
  const search = (searchTerm) => {
    setSearchText(searchTerm);
    setPage(1);
  };
  const debouncedValue = useDebounce(searchText, 400);

  // controllers for popovers
  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  // drawer controller functions
  // function to manage add product drawer
  const openAddProductDrawer = () => {
    setAddProductDrawer(true);
    hide();
  };
  const closeAddProductDrawer = () => {
    setAddProductDrawer(false);
  };

  //function to manage add category drawer
  const openAddCategoryDrawer = () => {
    setAddCategoryDrawer(true);
    hide();
  };
  const closeAddCategoryDrawer = () => {
    setAddCategoryDrawer(false);
  };

  // buttons for a popover
  const buttons = (
    <div className="flex flex-col">
      <Button onClick={openAddProductDrawer} className="px-1 py-1 border-none">
        <div className="flex flex-row gap-1">
          <IoFastFoodOutline className="text-lg text-[#c82127]" />+ Product
        </div>
      </Button>
      <Button onClick={openAddCategoryDrawer} className="px-1 py-1 border-none">
        <div className="flex gap-1">
          <BiCategoryAlt className="text-lg text-[#c82127]" />+ Category
        </div>
      </Button>
    </div>
  );

  //function to manage edit product drawer
  const openEditProductDrawer = () => {
    setEditProductDrawer(true);
  };
  const closeEditProductDrawer = () => {
    setEditProductDrawer(false);
  };
  //function to manage edit category drawer
  const openEditCategoryDrawer = () => {
    setEditCategoryDrawer(true);
  };
  const closeEditCategoryDrawer = () => {
    setEditCategoryDrawer(false);
  };

  //functions to set product and category for operation
  const productForOperations = (product) => {
    setProductForOperation(product);
  };

  const categoryForOperations = (category) => {
    setCategoryForOperation(category);
  };

  const onSuccess = (data) => {
    setAllCategories(data);
  };
  const onError = (error) => {
    message.warning(error?.response?.data?.message);
  };

  //varaibles for custom hook handles
  const client = useQueryClient();
  const { isLoading: categoryLoad } = useAllCategories(onSuccess, onError);

  const { mutate: deleteProduct } = useDeleteProduct();
  const { mutate: deleteCategory } = useDeleteCategory();

  const { data } = useQuery(
    ["allProducts", page, categoryChange, debouncedValue],
    () => fetchProducts(page, categoryChange, debouncedValue)
  );

  // handle category and page changes
  const handleCategoryChange = (id) => {
    setPage(1);
    setCategoryChange(id);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <div className="flex flex-row space-x-2 products">
      <div className="border-solid border-x-2 border-0 border-gray-100 w-fit ml-3 mt-4">
        <Divider>
          <h4 className="text-2xl">Categories</h4>
        </Divider>
        <div
          className="border-solid border-b-2 border-0 border-b-gray-800 w-full px-2 py-2 rounded-lg text-center text-sm hover:border-b-sky-500 hover:text-sky-500"
          onClick={() => handleCategoryChange(null)}
        >
          All Products
        </div>
        <div className="space-y-3">
          {categoryLoad ? (
            <Spin
              className="items-center text-center m-[20%] text-xs"
              size="small"
            />
          ) : (
            allCategories?.map((category) => {
              return (
                <div key={category?.id}>
                  <div
                    className="peer relative border-solid border-b-2 border-0 border-b-gray-800 w-auto px-2 py-2 rounded-lg text-center text-sm hover:border-b-sky-500 hover:text-sky-500"
                    onClick={() => {
                      handleCategoryChange(category?.id);
                    }}
                  >
                    {category?.name}
                    <EditOutlined
                      className="text-green-600 text-xs"
                      onClick={() => {
                        categoryForOperations(category);
                        openEditCategoryDrawer();
                      }}
                    />
                    <Popconfirm
                      title="Delete Category"
                      description="Are you sure to delete this Category?"
                      onConfirm={(e) => {
                        e.stopPropagation();
                        deleteCategory(category.id, {
                          onSuccess: (data) => {
                            message.warning("Category Deleted");
                            client.invalidateQueries("allCategories");
                            client.invalidateQueries("allProducts");
                          },
                          onError: (error) => {
                            message.warning(error?.response?.data?.message);
                          },
                        });
                      }}
                      onCancel={(e) => {
                        e.stopPropagation();
                        message.warning("ምንም ስራ አልተሰራም");
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <IoIosRemoveCircleOutline className="text-red-600 text-xs ml-1" />
                    </Popconfirm>
                  </div>

                  {/* <div className="hidden z-20 peer-hover:flex hover:flex w-auto absolute left-36  flex-col bg-gray-200 mt-0 drop-shadow-lg">
                    {category?.subcategories?.map((subcat) => {
                      return (
                        <div
                          key={subcat?.id}
                          className="px-5 py-3 text-center hover:text-sky-500"
                        >
                          {subcat?.name}
                        </div>
                      );
                    })}
                  </div> */}
                </div>
              );
            })
          )}
        </div>
      </div>
      <div className="mt-4 flex flex-col">
        <div className="space-x-3">
          {/* <Button onClick={openAddProductDrawer}>
            <MdProductionQuantityLimits />
            Add Product
          </Button>
          <Button onClick={openAddCategoryDrawer}>
            <MdOutlineCategory />
            Add Category
          </Button> */}
          <Input.Search
            onChange={(e) => search(e.target.value)}
            placeholder="looking for which products ?"
            className="w-[45%]"
          />
          <Popover
            content={buttons}
            title="Register New"
            placement="leftTop"
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
          >
            <Button className="space-x-1 hover:cursor-pointer">
              <IoIosAddCircleOutline className="text-sm" />
              <span className="text-base">New</span>
            </Button>
          </Popover>
        </div>
        <div className="mt-2 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-3">
          {data?.data?.map((product, index) => {
            return (
              <div key={index}>
                <Card
                  hoverable
                  key={index}
                  className="shadow-inner hover:border-[#c82127]"
                >
                  <Image.PreviewGroup>
                    <Carousel autoplay infinite={false}>
                      {product?.image_paths.length ? (
                        product?.image_paths?.map((imagepath, imgindex) => {
                          return <Image key={imgindex} src={imagepath} />;
                        })
                      ) : (
                        <Image src={NoImage} />
                      )}
                    </Carousel>
                  </Image.PreviewGroup>
                  <Meta
                    key={index}
                    title={product?.name}
                    description={
                      <div className="flex flex-col">
                        <div>
                          {product.description?.length > 20
                            ? product.description.slice(0, 20) + " ..."
                            : product.description}
                        </div>
                        <div className="text-green-600">
                          {parseInt(product.discount) +
                            parseInt(product.price) !=
                          parseInt(product.price) ? (
                            <strike className="text-red-600">
                              {parseInt(product.discount) +
                                parseInt(product.price)}{" "}
                              ብር
                            </strike>
                          ) : (
                            ``
                          )}
                          {" " + product.price + " ብር"}
                          <br />
                          {product.image_paths.length === 1 ||
                          product.image_paths.length === 0
                            ? product.image_paths.length + " image"
                            : product.image_paths.length + " images"}
                          <br />
                        </div>
                        <div>{product?.left_in_stock + " ስቶክ ላይ የቀረ"}</div>
                        <div className="space-x-5">
                          {/* <Switch
                            size="small"
                            checked={product?.is_active}
                            onChange={() =>
                              handleActivateDeactivateProductVisiblity(product)
                            }
                          /> */}
                          <EditOutlined
                            className="text-green-600 text-base mr-3 hover:text-lg"
                            onClick={() => {
                              productForOperations(product);
                              openEditProductDrawer();
                            }}
                          />
                          <Popconfirm
                            title="Delete Product"
                            description="Are you sure to delete this Product?"
                            onConfirm={(e) => {
                              e.stopPropagation();
                              deleteProduct(product.id, {
                                onSuccess: (data) => {
                                  message.warning("Product Deleted");
                                  client.invalidateQueries("allProducts");
                                },
                                onError: (error) => {
                                  message.warning(
                                    error?.response?.data?.message
                                  );
                                },
                              });
                            }}
                            onCancel={(e) => {
                              e.stopPropagation();
                              message.warning("ምንም ስራ አልተሰራም");
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <DeleteOutlined
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              className="text-red-600 text-base hover:text-lg"
                            />
                          </Popconfirm>
                        </div>
                      </div>
                    }
                  />
                </Card>
              </div>
            );
          })}
        </div>
        {/* </Row> */}
        <div className="mx-auto mt-4">
          {data?.meta?.last_page > 1 && (
            <Pagination
              current={page}
              total={data?.meta?.last_page * 10}
              showSizeChanger={false}
              onChange={(page) => {
                handlePageChange(page);
              }}
            />
          )}
        </div>
      </div>
      <Drawer
        open={addProductDrawer}
        closable={false}
        onClose={closeAddProductDrawer}
        width={600}
      >
        <AddProduct closeAddProductDrawer={closeAddProductDrawer} />
      </Drawer>
      <Drawer
        open={addCategoryDrawer}
        closable={false}
        onClose={closeAddCategoryDrawer}
      >
        <AddCategory closeAddCategoryDrawer={closeAddCategoryDrawer} />
      </Drawer>

      <Drawer
        onClose={closeEditProductDrawer}
        open={editProductDrawer}
        closable={false}
        width={600}
      >
        <EditProduct
          closeEditProductDrawer={closeEditProductDrawer}
          productForOperations={productForOperation}
        />
      </Drawer>
      <Drawer
        closable={false}
        open={editCategoryDrawer}
        onClose={closeEditCategoryDrawer}
      >
        <EditCategory
          categoryForOperations={categoryForOperation}
          closeEditCategoryDrawer={closeEditCategoryDrawer}
        />
      </Drawer>
    </div>
  );
};

export default Products;
