/*eslint-disable*/
// @author:Yohannes Assebe
import { useEffect } from "react";
import { BellOutlined } from "@ant-design/icons";
import { Badge, Popover, Divider, Tooltip, message } from "antd";
import { GoCheck, GoChecklist } from "react-icons/go";
import {
  useUnreadNotifications,
  useNotifications,
  useMarkAsRead,
  useMarkAllAsRead,
} from "../hooks/useNotifications";
import echo from "../ws/wsConfig"
import { useQueryClient } from "react-query";
const Notifications = () => {
  const { data: unread_notifications, isLoading: load_unread } =
    useUnreadNotifications();
  // console.log(unread_notifications);
  const { data: notifications, isLoading: load_notifications } =
    useNotifications();
  const { mutate: markAsRead } = useMarkAsRead();
  const { mutate: markAllAsSeen } = useMarkAllAsRead();
  const markAsReadHandler = (id) => {
    markAsRead(id, {
      onSuccess: () => {
        client.invalidateQueries("unread_notifications");
        client.invalidateQueries("Notifications");
      },
      onError: (err) => {
        message.warning(err?.response?.data?.message);
      },
    });
  };

  const markAllAsSeenHandler = () => {
    markAllAsSeen({
      onSuccess: () => {
        client.invalidateQueries("unread_notifications");
        client.invalidateQueries("Notifications");
      },
      onError: (err) => {
        message.warning(err?.response?.data?.message);
      },
    });
  };

  const client = useQueryClient();
  const content = () => (
    <div className="-mt-6 allNotifications">
      <Divider>
        <div className="text-green-500">
          Unread Notifications
          <Tooltip title="Mark all as read">
            <GoChecklist
              className="text-lg items-center justify-center"
              onClick={markAllAsSeenHandler}
            />
          </Tooltip>
        </div>
      </Divider>
      {unread_notifications?.map((notification) => {
        return (
          <p key={notification.id} className="font-mono">
            {[
              "Order with id #",
              notification.data.order_id,
              "make a payment of",
              notification.data.amount,
              " ብር",
            ].join(" ")}
            <Tooltip title="mark as read">
              <GoCheck
                className="text-lg text-green-500"
                onClick={() => markAsReadHandler(notification.id)}
              />
            </Tooltip>
          </p>
        );
      })}
      <Divider>
        <div className="text-red-400">All Notifications</div>
      </Divider>
      {notifications?.map((notification) => {
        return (
          <p key={notification.id} className="font-mono">
            {[
              "Order with id #",
              notification.data.order_id,
              "make a payment of",
              notification.data.amount,
              " ብር",
            ].join(" ")}
          </p>
        );
      })}
    </div>
  );
  
  // listen for new Notifications
  useEffect(() => {
    echo
      .private(`users.${JSON.parse(sessionStorage.getItem("user")).id}`)
      .notification((notification) => {
        console.log(
          "Socket Notification listened correctly Jahnny :) ",
          notification
        );
        client.invalidateQueries("unread_notifications");
        client.invalidateQueries("Notifications");
      });

    // return () => {
    //     echo.leave(`users.${JSON.parse(sessionStorage.getItem("user")).id}`);
    // };
  }, []);

  return (
    <Popover content={content} loading={load_unread || load_notifications}>
      <Badge
        count={unread_notifications?.length}
        style={{ backgroundColor: "#001529", color: "white" }}
      >
        <BellOutlined style={{ fontSize: "24px", color: "#C92127" }} />
      </Badge>
    </Popover>
  );
};

export default Notifications;
