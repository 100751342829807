import { useQuery, useMutation } from "react-query";
import api from "../adapter/base";

const allCategories = async () => {
  const res = await api.get("categories");
  const dt = res?.data?.data;
  return dt;
};

const updateCategory = (data) => {
  return api.post(`categories/${data["id"]}`, data.category);
};

const deleteCategory = (id) => {
  return api.delete(`categories/${id}`);
};

const addCategory = (category) => {
  return api.post("categories", category);
};

//used to get all category lists
export const useAllCategories = (onSuccess, onError) => {
  return useQuery("allCategories", allCategories, { onSuccess, onError });
};

//used to register category
export const useAddCategory = () => {
  return useMutation(addCategory);
};

//used to update category
export const useEditCategory = () => {
  return useMutation(updateCategory);
};

//used to delete category
export const useDeleteCategory = () => {
  return useMutation(deleteCategory);
};
