/* eslint-disable */
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Table, Spin } from "antd";
import api from "../adapter/base";
import OrderDetails from "./orderDetail";
import echo from "../ws/wsConfig";
const fetchSuccessfullyDeliveredOrders = async (page = 1) => {
  const response = await api.get(`orders?status=successful&user&page=${page}`);
  const data = await response.data;
  return data;
};
function SuccessfullyDelivered() {
  const [page, setPage] = useState(1);
  const { data, isLoading } = useQuery(
    ["successfully-delivered-orders", page],
    () => fetchSuccessfullyDeliveredOrders(page)
  );
  const client = useQueryClient();
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [key, setKey] = useState(0);
  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };
  const onRow = (record) => {
    return {
      onClick: () => {
        setSelectedRowData(record);
        setKey(key + 1);
      },
    };
  };
  const columns = [
    {
      title: "Order ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Customer Name",
      dataIndex: ["user", "name"],
      key: "customerName",
    },
    {
      title: "Phone Number",
      dataIndex: ["user", "phone_number"],
      key: "phoneNumber",
    },
    {
      title: "Total Cost",
      dataIndex: "total_cost",
      key: "ordercost",
      render: (total_cost) => {
        return total_cost + " ብር";
      },
    },
    {
      title: "Order Date",
      dataIndex: "created_at",
      key: "order_date",
      render: (order_date) => {
        return order_date.split("T")[0];
      },
    },
    {
      title: "Approved By",
      dataIndex: ["approver", "name"],
      key: "approver",
    },
  ];
  useEffect(() => {
    echo.private("orders.status-change").listen("OrderDelivered", (data) => {
      client.invalidateQueries(["successfully-delivered-orders", 1]);
    });
    return () => {
      echo.leave("orders.status-change");
    };
  }, []);
  return (
    <>
      <Table
        columns={columns}
        dataSource={data?.data?.map((dt) => ({ ...dt, key: dt.id }))}
        pagination={{
          current: page,
          position: ["bottomCenter"],
          total: data?.meta?.last_page * 10,
          showSizeChanger: false,
        }}
        loading={{ indicator: <Spin size="small" />, spinning: isLoading }}
        onChange={handleTableChange}
        className="custom-pagination"
        onRow={onRow}
      />
      {selectedRowData && (
        <OrderDetails
          order={selectedRowData}
          key={key}
          onClose={() => setSelectedRowData(null)}
        />
      )}
    </>
  );
}

export default SuccessfullyDelivered;
