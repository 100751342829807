// @author : Yohannes Assebe
import React, { useState } from "react";
import Adika from "../images/Adika_A.png";
import UserImage from "../images/user.png";
import { Avatar, Popover, Button, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import echo from "../ws/wsConfig";
import Notifications from "../components/notifications";

function Header() {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  const handleLogout = () => {
    echo.leave(`users.${JSON.parse(sessionStorage.getItem("user")).id}`);
    sessionStorage.clear("user");
    sessionStorage.clear("token");
    navigate("/login");
    setVisible(false);
  };

  const content = (
    <div>
      <p>Are you sure you want to log out?</p>
      <Button onClick={handleLogout}>Logout</Button>
    </div>
  );
  return (
    <div className="flex flex-row-reverse justify-between bg-[#001529] text-white h-16">
      <div className="flex flex-row-reverse mr-5  ">
        <div className="flex flex-col">
          <div className="text-sm font-bold mt-2">እንኳን በደህና መጡ!</div>
          <div className="text-sm font-mono text-center">
            {user?.store?.name ? user?.store?.name : ""}
          </div>
        </div>
        <div className="w-30 h-60 rounded-lg mt-3">
          <Popover
            content={content}
            title="Logout"
            trigger="click"
            open={visible}
            onOpenChange={handleVisibleChange}
          >
            <Tooltip
              title={
                user?.first_name || user?.last_name
                  ? [user?.first_name, user?.last_name].join(" ")
                  : " "
              }
            >
              <Avatar
                src={
                  user?.profile_thumbnail_path
                    ? user?.profile_thumbnail_path
                    : UserImage
                }
              />
            </Tooltip>
          </Popover>
        </div>
        <div className="items-center justify-center mr-5 mt-6">
          <Notifications />
        </div>
      </div>
      <div className="w-30 h-60 rounded-lg mt-3 ml-20 flex flex-row text-center">
        <img src={Adika} alt="Adika Logo" width={40} height={40} />
        <h6 className="items-center mt-4 text-lg font-bold">
          Adika Taxi Services PLC
        </h6>
      </div>
    </div>
  );
}
export default Header;
