/* eslint-disable */
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Table, Button, Popover, Input, Form, message, Spin } from "antd";
import api from "../adapter/base";
import OrderDetails from "./orderDetail";
import { useApproveOrder } from "../hooks/useOrder";
import { useQueryClient } from "react-query";
import echo from "../ws/wsConfig";
const fetchAcceptedOrders = async (page = 1) => {
  const response = await api.get(
    `orders?status=pending-pickup&user&page=${page}`
  );
  const data = await response.data;
  return data;
};
function AcceptedOrders() {
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const { data, isLoading } = useQuery(["accepted-orders", page], () =>
    fetchAcceptedOrders(page)
  );
  const { mutate: approveOrder, isLoading: loadApprove } = useApproveOrder();
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [key, setKey] = useState(0);
  const client = useQueryClient();
  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };
  const onRow = (record) => {
    return {
      onClick: () => {
        setSelectedRowData(record);
        setKey(key + 1);
      },
    };
  };

  const handleApprove = (values, id) => {
    approveOrder(
      { orderId: id, data: { driver_phone_number: values.phone_number } },
      {
        onSuccess: () => {
          message.success("Approved Successfully");
          client.invalidateQueries(["accepted-orders", page]);
          client.invalidateQueries(["pending-delivery-orders", 1]);
          form.resetFields();
        },
        onError: (error) => {
          message.warning(error?.response?.data?.message);
          form.resetFields();
        },
      }
    );
  };

  const content = (id) => {
    return (
      <Form
        onFinish={(e) => handleApprove(e, id)}
        form={form}
        layout="vertical"
      >
        <Form.Item name="phone_number" label="Driver Phone Number">
          <Input
            maxLength={12}
            showCount={true}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            loading={loadApprove}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            Approve
          </Button>
        </Form.Item>
      </Form>
    );
  };
  const columns = [
    {
      title: "Order ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Customer Name",
      dataIndex: ["user", "name"],
      key: "customerName",
    },
    {
      title: "Phone Number",
      dataIndex: ["user", "phone_number"],
      key: "phoneNumber",
    },
    {
      title: "Total Cost",
      dataIndex: "total_cost",
      key: "ordercost",
      render: (total_cost) => {
        return total_cost + " ብር";
      },
    },
    {
      title: "Order Date",
      dataIndex: "created_at",
      key: "order_date",
      render: (order_date) => {
        return order_date.split("T")[0];
      },
    },

    // {
    //   title: "Accepted By",
    //   dataIndex: ["acceptor", "name"],
    //   key: "acceptor",
    // },
    // {
    //   title: "Accepted At",
    //   dataIndex: "accepted_at",
    //   key: "accepted_at",
    // },
    {
      title: "Actions",
      key: "actions",
      dataIndex: "id",
      render: (orderId) => (
        <Popover content={content(orderId)} trigger="click">
          <Button
            onClick={
              (e) => e.stopPropagation()
              // handleApprove(orderId)
            }
          >
            Approve
          </Button>
        </Popover>
      ),
    },
  ];

  useEffect(() => {
    echo.private("orders.paid").listen("OrderPaid", (newOrder) => {
      console.log("event listened correctly", newOrder);
      client.invalidateQueries(["accepted-orders", 1]);
    });
    return () => {
      echo.leave("orders.paid");
    };
  }, []);

  return (
    <>
      <Table
        columns={columns}
        dataSource={data?.data?.map((dt) => ({ ...dt, key: dt.id }))}
        pagination={{
          current: page,
          position: ["bottomCenter"],
          total: data?.meta?.last_page * 10,
          showSizeChanger: false,
        }}
        loading={{ indicator: <Spin size="small" />, spinning: isLoading }}
        onChange={handleTableChange}
        onRow={onRow}
        className="custom-pagination"
      />
      {selectedRowData && (
        <OrderDetails
          order={selectedRowData}
          key={key}
          onClose={() => setSelectedRowData(null)}
        />
      )}
    </>
  );
}

export default AcceptedOrders;
