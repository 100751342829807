/* eslint-disable */
import { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Table, Spin } from "antd";
import OrderDetails from "./orderDetail";
import { fetchSearchedOrders } from "../hooks/useOrder";
import echo from "../ws/wsConfig";
function SearchedOrders({ filters }) {
  const [page, setPage] = useState(1);
  const { data, isLoading } = useQuery(["searched-orders", page, filters], () =>
    fetchSearchedOrders(filters, page)
  );
  const client = useQueryClient();
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [key, setKey] = useState(0);
  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };
  const onRow = (record) => {
    return {
      onClick: () => {
        setSelectedRowData(record);
        setKey(key + 1);
      },
    };
  };
  const columns = [
    {
      title: "Order ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Customer Name",
      dataIndex: ["user", "name"],
      key: "customerName",
    },
    {
      title: "Phone Number",
      dataIndex: ["user", "phone_number"],
      key: "phoneNumber",
    },
    {
      title: "Order Cost",
      dataIndex: "order_cost",
      key: "ordercost",
      render: (cost) => {
        return `${cost} ብር`;
      },
    },
    {
      title: "Delivery Cost",
      dataIndex: "delivery_cost",
      key: "deliverycost",
      render: (cost) => {
        return `${cost} ብር`;
      },
    },
    {
      title: "Total Cost",
      dataIndex: "total_cost",
      key: "totalcost",
      render: (cost) => {
        return `${cost} ብር`;
      },
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created at",
      render: (created_at) => {
        return created_at.split("T")[0];
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Payment",
      dataIndex: "payment_method",
      key: "payment",
    },
  ];
  useEffect(() => {
    setPage(1);
  }, [filters]);

  useEffect(() => {
    echo.private("orders.created").listen("OrderCreated", (order) => {
      console.log("event listened correctly Jahnny:)", order);
      client.invalidateQueries("searched-orders", 1);
    });

    return () => {
      echo.leave("orders.created");
    };
  }, []);
  return (
    <>
      <Table
        columns={columns}
        dataSource={data?.data?.map((dt) => ({ ...dt, key: dt.id }))}
        pagination={{
          current: page,
          position: ["bottomCenter"],
          total: data?.meta?.last_page * 10,
          showSizeChanger: false,
        }}
        loading={{ indicator: <Spin size="small" />, spinning: isLoading }}
        onChange={handleTableChange}
        className="custom-pagination"
        onRow={onRow}
      />
      {selectedRowData && (
        <OrderDetails
          order={selectedRowData}
          key={key}
          onClose={() => setSelectedRowData(null)}
        />
      )}
    </>
  );
}

export default SearchedOrders;
