import React from "react";
import { Typography, Button } from "antd";
import { Link } from "react-router-dom";
import AdikaLogo from "../images/Adika_A.png";
const { Title, Paragraph } = Typography;

function LandingPage() {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col px-4 sm:px-6 lg:px-8">
      <div className="max-w-md mx-auto w-full mt-[5%]">
        <div className="flex justify-between mb-5">
          <img src={AdikaLogo} alt="Adika Logo" className="w-24 h-24 mr-1" />
          <Title
            level={1}
            className="text-4xl md:text-5xl font-extrabold text-gray-900 leading-tight"
          >
            Welcome to Adika Taxi Services PLC
          </Title>
        </div>
        <Paragraph className="text-lg text-gray-600 text-center mb-8">
          Manage Your Own Products,Orders and Categories with ease using our
          powerful management system.
        </Paragraph>
        <div className="flex justify-center">
          <Link to="/login">
            <Button className="mr-4">Login</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
