/* eslint-disable */
import { Avatar, Modal, Table } from "antd";
import { useState } from "react";
function OrderDetails({ order }) {
  const [visible, setVisible] = useState(true);
  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image_paths",
      render: (image) => {
        return <Avatar src={<img src={image[0]} alt="Product" />} />;
      },
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "product",
    },
    {
      title: "Quantity",
      dataIndex: ["pivot", "quantity"],
      key: "quantity",
    },
    {
      title: "Price",
      dataIndex: ["pivot", "price"],
      key: "price",
      render: (price) => <span>{`${price} ብር`}</span>,
    },
    {
      title: "Discount",
      dataIndex: ["pivot", "discount"],
      key: "discount",
      render: (discount) => <span>{`${discount} ብር`}</span>,
    },
    {
      title: "Total",
      key: "discount",
      render: (text, record) => (
        <span>{`${
          (parseFloat(record?.pivot?.price) -
            parseFloat(record?.pivot?.discount)) *
          parseFloat(record?.pivot?.quantity)
        }  ብር`}</span>
      ),
    },
  ];

  return (
    <>
      <Modal
        title={`Order #${order.id} Products Details`}
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Table
          dataSource={order?.products?.map((product) => ({
            ...product,
            key: product.id,
          }))}
          columns={columns}
          pagination={false}
          rowKey="key"
          style={{ marginBottom: "40px" }}
        />
      </Modal>
    </>
  );
}

export default OrderDetails;
