// @author : Yohannes Assebe
import { message } from "antd";
import axios from "axios";
const api = axios.create({
  baseURL: "https://stores.adikataxi.com/api/v1/store-manager",
});
api.interceptors.request.use(
  (req) => {
    const token = sessionStorage.getItem("token");
    if (token && (window.location !== "/" || window.location !== "/login"))
      req.headers["Authorization"] = `Bearer ${token}`;
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);
api.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (
      error?.response?.status === 401 &&
      window.location.pathname !== "/login"
    ) {
      window.location = "/login";
      message.error("Timeout", 2);
      return Promise.reject(error);
    } else if (error?.response?.status === 403) {
      message.error("UnAuthorized Trial", 2);
      window.location = "/login";
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);
export default api;
