/* eslint-disable */
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Table, Button, message, Spin } from "antd";
import api from "../adapter/base";
import OrderDetails from "./orderDetail";
import echo from "../ws/wsConfig";
import { useQueryClient } from "react-query";
import {
  useDeliveryStatus,
  useRetryPickUpDelivery,
  useViewTripStatus,
} from "../hooks/useOrder";
const fetchPendingDeliveryOrders = async (page = 1) => {
  const response = await api.get(
    `orders?status=pending-delivery&user&page=${page}`
  );
  const data = await response.data;
  return data;
};
function PendingDelivery() {
  const [page, setPage] = useState(1);
  const { data, isLoading } = useQuery(["pending-delivery-orders", page], () =>
    fetchPendingDeliveryOrders(page)
  );
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [key, setKey] = useState(0);
  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };
  const onRow = (record) => {
    return {
      onClick: () => {
        setSelectedRowData(record);
        setKey(key + 1);
      },
    };
  };
  // client
  const client = useQueryClient();
  const { mutate: check_delivery_status, isLoading: load_delivery_status } =
    useDeliveryStatus();
  const { mutate: retry_pickup_delivery, isLoading: load_retry_pickup } =
    useRetryPickUpDelivery();
  const { mutate: viewTrip, isLoading: load_view_trip } = useViewTripStatus();
  // check status of delivery
  const checkStatus = (order_id) => {
    check_delivery_status(order_id, {
      onSuccess: (data) => {
        const res_message = data?.data?.status.includes("_")
          ? data?.data?.status.split("_").join(" ").toLowerCase()
          : data?.data?.status;
        message.success(res_message);
      },
      onError: (err) => {
        message.warning(err?.response?.data?.message);
      },
    });
  };

  // retry pickup
  const retryPickup = (order_id) => {
    retry_pickup_delivery(order_id, {
      onSuccess: (data) => {
        message.success(data?.data?.message);
      },
      onError: (error) => {
        message.warning(error?.response?.data?.message);
      },
    });
  };
  // handle view trips
  const handleViewTrip = (order_id) => {
    viewTrip(order_id, {
      onSuccess: (data) => {
        const res_message = data?.data?.message?.includes(":")
          ? data?.data?.message.split(":")[1]
          : data?.data?.message;
        if (data?.data?.message?.includes(":")) message.warning(res_message);
        else window.open(data?.data?.url, "_blank");
      },
      onError: (err) => {
        message.warning(err?.response?.data?.message);
      },
    });
  };
  const columns = [
    {
      title: "Order ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Customer Name",
      dataIndex: ["user", "name"],
      key: "customerName",
    },
    {
      title: "Phone Number",
      dataIndex: ["user", "phone_number"],
      key: "phoneNumber",
    },
    {
      title: "Total Cost",
      dataIndex: "total_cost",
      key: "ordercost",
      render: (total_cost) => {
        return total_cost + " ብር";
      },
    },
    {
      title: "Approved By",
      dataIndex: ["approver", "name"],
      key: "approver",
    },
    {
      title: "Approved At",
      dataIndex: "approved_at",
      key: "approved_at",
    },
    {
      title: "Actions",
      dataIndex: "id",
      render: (orderId) => {
        return (
          <div key={orderId}>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                checkStatus(orderId);
              }}
              loading={load_delivery_status}
            >
              Check Status
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                retryPickup(orderId);
              }}
              loading={load_retry_pickup}
            >
              Retry Pickup
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleViewTrip(orderId);
              }}
              loading={load_view_trip}
            >
              View Trip
            </Button>
          </div>
        );
      },
    },
  ];
  //listen for socket connection inorder to invalidate client query
  useEffect(() => {
    echo.private("orders.status-change").listen("OrderApproved", (data) => {
      client.invalidateQueries(["pending-delivery-orders", 1]);
    });
    return () => {
      echo.leave("orders.status-change");
    };
  }, []);
  return (
    <>
      <Table
        columns={columns}
        dataSource={data?.data?.map((dt) => ({ ...dt, key: dt.id }))}
        pagination={{
          current: page,
          position: ["bottomCenter"],
          total: data?.meta?.last_page * 10,
          showSizeChanger: false,
        }}
        loading={{ indicator: <Spin size="small" />, spinning: isLoading }}
        className="custom-pagination"
        onChange={handleTableChange}
        onRow={onRow}
      />
      {selectedRowData && (
        <OrderDetails
          order={selectedRowData}
          key={key}
          onClose={() => setSelectedRowData(null)}
        />
      )}
    </>
  );
}

export default PendingDelivery;
