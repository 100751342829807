import { Table, Avatar, Rate, Tag, Spin } from "antd";
import { useRatings } from "../hooks/useRating";
import { dateConvertor, RegisterationDate } from "../common/time_calculator";
import { FaRegClock } from "react-icons/fa";
const Review = () => {
  const { data: restaurantRatings, isLoading: load } = useRatings();
  const columns = [
    {
      title: "Customer",
      render: (record) => {
        return (
          <div className="flex flex-row space-x-1">
            <Avatar src={record?.user?.profile_image} />
            <div className="flex flex-col">
              <div className="text font-bold">
                {[record?.user?.first_name, record?.user?.last_name].join(" ")}
              </div>
              {record?.user?.email}
            </div>
          </div>
        );
      },
    },
    {
      title: "Review",
      render: (record) => {
        return (
          <div className="flex flex-col space-y-1">
            <Rate
              count={5}
              value={record.rating}
              className="text-xs"
              disabled
            />
            <div className="text-sm">{record?.description}</div>
            <div className="flex text-xs text-gray-500 pt-1 pl-4  gap-1">
              <FaRegClock />
              {RegisterationDate(record.created_at)}
            </div>
          </div>
        );
      },
    },
    {
      title: "Review Date",
      dataIndex: "created_at",
      render: (created_at) => {
        return dateConvertor(created_at);
      },
    },
    {
      title: "status",
      dataIndex: "is_active",
      render: (is_active) => {
        return (
          <Tag color={is_active ? "green" : "#c92127"}>
            {is_active ? "Visible" : "Invisible"}
          </Tag>
        );
      },
    },
  ];
  return (
    <div className="flex justify-center mt-2">
      <Table
        className="w-[60%]"
        loading={{ indicator: <Spin size="small" />, spinning: load }}
        dataSource={restaurantRatings?.data.map((res) => ({
          key: res.id,
          ...res,
        }))}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default Review;
