// @author : Yohannes Assebe
import { useQuery, useMutation } from "react-query";
import api from "../adapter/base";
import qs from "qs";
const allProducts = async () => {
  const response = await api.get("products");
  const products = response?.data?.data;
  return products;
};
const allProductsWithoutPagination = async () => {
  const response = await api.get("products?all");
  const products = response?.data?.data;
  return products;
};
const product = (id) => {
  return api.get(`products/${id}`);
};

const productPage = (pageNumber) => {
  return api.get("products", {
    params: { page: pageNumber },
    paramsSerializer: (params) => {
      return qs.stringify(params, { strictNullHandling: true });
    },
  });
};
const deleteProduct = (id) => {
  return api.delete(`products/${id}`);
};
const addProduct = (product) => {
  return api.post("products", product);
};

const updateProduct = (product) => {
  return api.post(`products/${product.id}`, product.data);
};

//fetch name filtered products if not all products displayed
const searchProduct = async (name) => {
  const data = await api.get(`products${name ? `?name=${name}` : `?all`}`);
  return data?.data?.data;
};

//used to fetch all products
export const useAllProducts = () => {
  return useQuery("allProducts", allProducts);
};
//used to get single product
export const useProduct = (id, onSuccess, onError) => {
  return useQuery(["product", id], product(id), { onSuccess, onError });
};

//used for product pagination
export const useProductPagination = (pageNumber) => {
  return useQuery(["productPage", pageNumber], productPage(pageNumber), {
    keepPreviousData: true,
  });
};

//used to register new product
export const useAddProduct = () => {
  return useMutation(addProduct);
};

// used to update product
export const useEditProduct = () => {
  return useMutation(updateProduct);
};

//used to delete product
export const useDeleteProduct = () => {
  return useMutation(deleteProduct);
};

//used to get all products
export const useAllProduct = () => {
  return useQuery(["allProductss"], allProductsWithoutPagination);
};

// used to fetch filtered data
export const useSearchByName = (name) => {
  return useQuery(["searchProduct", name], () => searchProduct(name));
};
