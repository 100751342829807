/*eslint-disable*/
import { useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Upload,
  InputNumber,
  message,
  Divider,
} from "antd";
import { useQueryClient } from "react-query";
import { PlusOutlined } from "@ant-design/icons";
import { useAllCategories } from "../hooks/useCategories";
import { useAddProduct } from "../hooks/useProducts";
const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const uploadButton = (
  <div>
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
      }}
    >
      Upload
    </div>
  </div>
);

const { Option } = Select;
const AddProduct = ({ closeAddProductDrawer }) => {
  const client = useQueryClient();
  const [form] = Form.useForm();

  //mutation and query data from api
  const { data: categories, isLoading: load_categories } = useAllCategories();
  const { mutate: registerProduct, isLoading: load_add_product } =
    useAddProduct();

  const onFinish = (values) => {
    const fd = new FormData();
    Object?.keys(values)?.map((key) => {
      if (key === "upload") {
        values["upload"].map((image) => {
          fd.append("images[]", image.originFileObj, image.originFileObj.name);
        });
        delete values["upload"];
        return;
      }
      fd.append(key, values[key]);
    });

    registerProduct(fd, {
      onSuccess: (data) => {
        message.success(data?.data?.data?.name + " registered Succesfully");
        client.invalidateQueries(["allProducts", 1]);
        form.resetFields();
        closeAddProductDrawer();
      },
      onError: (error) => {
        message.warning(error?.response?.data?.message);
      },
    });
  };

  return (
    <div className="-mt-4">
      <Divider>
        <h4>Register New Product</h4>
      </Divider>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Row>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Product Name"
              rules={[
                {
                  required: true,
                  message: "Please Enter Product Name",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item
              name="price"
              label="የእቃው ዋጋ"
              rules={[
                {
                  required: true,
                  message: "Price field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="name_am"
              label="የእቃው ስም በአማርኛ"
              rules={[
                {
                  required: true,
                  message: "እባኮን የእቃውን ስም ያስገቡ",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item
              name="discount"
              label="ዋጋው ላይ የተደረገ ቅናሽ"
              rules={[
                {
                  required: true,
                  message: "Price discount",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} min={0} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="description"
              label="Description In English"
              rules={[
                {
                  required: false,
                  message: "Please Enter Appropriate Description",
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item
              name="category_id"
              label="Category"
              rules={[
                { required: true, message: "Category Field is required" },
              ]}
            >
              <Select loading={load_categories} className="w-full">
                {categories?.map((category) => {
                  return (
                    <Option value={category?.id} key={category?.id}>
                      {category?.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="description_am"
              label="ገለጻ ስለ እቃው(አማርኛ)"
              rules={[
                {
                  required: false,
                  message: "እባኮን መግለጫውን አስተካክለው ያስገቡ",
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}></Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="upload"
              label="Images For Product"
              valuePropName="fileList"
              accept=".jpg, .jpeg, .png, .bmp, .gif, .svg,.webp"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: true,
                  message: "እባኮን ቢያንስ አንድ ፎቶ ይጨምሩ/this field is required",
                },
              ]}
            >
              <Upload
                name="images"
                listType="picture-card"
                beforeUpload={(file) => {
                  return false;
                }}
              >
                {uploadButton}
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          wrapperCol={{
            span: 8,
            offset: 6,
          }}
        >
          <Button htmlType="submit" loading={load_add_product}>
            Register Product
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default AddProduct;
