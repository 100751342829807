/* eslint-disable */
// @author : Yohannes Assebe
import { DatePicker, Table, Button, Cascader, Row, Col, Spin, Tag } from "antd";
import { useState } from "react";
import {
  useDownloadFilteredOrderInExcel,
  useReportOrder,
} from "../hooks/useOrder";
import { dateConvertor } from "../common/time_calculator";
const Reports = () => {
  const { RangePicker } = DatePicker;
  const [status, setStatus] = useState();
  const [date, setDate] = useState();
  const [page, setPage] = useState(1);
  const { mutate: downloadExcelFile, isLoading: loadDownload } =
    useDownloadFilteredOrderInExcel(status, date);
  const { data: orders, isLoading: load } = useReportOrder(status, date, page);
  const handleStatusChange = (value) => {
    typeof value === "undefined" && setStatus("all");
    value && setStatus(value[0]);
    setPage(1);
  };
  const handleDateChange = (date) => {
    setDate(date);
    setPage(1);
  };
  const handlePageChange = (page) => {
    setPage(page);
  };

  const downloadExcel = () => {
    downloadExcelFile(
      { status, date },
      {
        onSuccess: (data) => {
          const url = window.URL.createObjectURL(new Blob([data.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "ExcelFile.xlsx");
          document.body.appendChild(link);
          link.click();
        },
        onError: (error) => {
          console.log("Download Excel Error Happened");
          message.warning(error?.response?.data?.message);
        },
      }
    );
  };
  const options = [
    {
      value: "all",
      label: "All",
    },
    {
      value: "pending-pickup",
      label: "Pending PickUp",
    },
    {
      value: "pending-delivery",
      label: "Pending Delivery",
    },
    {
      value: "unpaid",
      label: "UnPaid",
    },
    {
      value: "successful",
      label: "Successful",
    },
  ];
  const columns = [
    {
      key: "1",
      title: "ID",
      dataIndex: "id",
      render: (text) => {
        return "# " + text;
      },
    },
    {
      key: "2",
      title: "customer",
      dataIndex: ["user", "name"],
    },
    {
      key: "3",
      title: "Order Cost",
      dataIndex: "order_cost",
      render: (text) => {
        return text + " ብር";
      },
    },
    {
      key: "4",
      title: "Date",
      dataIndex: "created_at",
      render: (created_at) => {
        return dateConvertor(created_at);
      },
    },
    {
      key: "5",
      title: "Status",
      dataIndex: "status",
      render(text, record) {
        return (
          <Tag color={text === "unpaid" ? "#c92127" : "green"}>{text}</Tag>
        );
      },
    },
    {
      key: "6",
      title: "Phone Number",
      dataIndex: ["user", "phone_number"],
    },
    {
      key: "8",
      align: "center",
      title: "Payment",
      dataIndex: "payment_method",
      render: (payment) => {
        return payment ?? "--";
      },
    },

    {
      key: "9",
      align: "center",
      title: "Approved By",
      dataIndex: "approver",
      render: (approver) => {
        return approver ?? "--";
      },
    },
    {
      key: "10",
      align: "center",
      title: "approved_at",
      dataIndex: "approved_at",
      render: (approved_at) => {
        return approved_at ?? "--";
      },
    },
  ];

  return (
    <div className="ml-[10%] mt-4 w-[80%]">
      <Row>
        <Col span={6} className="space-y-1">
          <label>Status</label>
          <Cascader
            options={options}
            allowClear
            placeholder="Select Status"
            style={{ display: "block", width: "100%" }}
            onChange={(value) => {
              handleStatusChange(value);
            }}
          />
        </Col>
        <Col span={6} offset={1} className="space-y-1">
          <label>Date</label>
          <RangePicker onChange={handleDateChange} allowClear />
        </Col>
        <Col span={8} offset={1} className="space-y-1">
          <label>Download Table</label>
          <br />
          <Button
            className="border-1 w-32 border-[#C92127] text-[#c92127]"
            onClick={() => {
              downloadExcel();
            }}
            loading={loadDownload}
            title="used to download table in excel"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-download"
              viewBox="0 0 16 16"
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />{" "}
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />{" "}
            </svg>
            Excel
          </Button>
        </Col>
      </Row>
      <div className="container mt-2">
        <Table
          columns={columns}
          loading={{ indicator: <Spin size="small" />, spinning: load }}
          dataSource={orders?.data?.map((order) => ({
            ...order,
            key: order?.id,
          }))}
          className="custom-pagination"
          pagination={{
            current: page,
            position: ["bottomCenter"],
            total: orders?.meta?.last_page * 10,
            showSizeChanger: false,
            onChange: (page) => {
              handlePageChange(page);
            },
          }}
        />
      </div>
    </div>
  );
};

export default Reports;
